<style lang='scss' scoped>
img {
    vertical-align: middle;
}
// 定义全局变量
.pc {
    background: url("../assets/bjimg.png") no-repeat;
    background-size: 100% 100%;
}
.mobile {
    background: url("../assets/sj.png") no-repeat;
    background-size: 100% 100%;
}
.bg {
    width: 100vw;
    height: 100vh;
    background: url("../assets/bjimg.png") no-repeat;
    background-size: 100% 100%;
}
// .index {
//     width: 56.22vh;
//     height: 100vh;
//     border: none;
// }
.pcclass {
    width: 56.22vh;
}
.mobileclass {
    width: 100vw;
}
.index {
    height: 100vh;
    overflow: auto;
    margin: 0 auto;
    .content {
        text-align: center;
        height: 100%;
        overflow: auto;
        background: url("../assets/bjimg.png") no-repeat;
        background-size: 100% 100%;
        margin: 0 auto;
        .title {
            margin-top: 10px;
            text-align: center;
            img {
                width: 35%;
            }
        }
        .text {
            font-size: 1.5vh;
            line-height: 1.5;
            opacity: 0.7;
            color: #fff;
            box-sizing: border-box;
            padding: 0 24px;
            overflow-wrap: break-word;
            margin: 0 auto;
            margin-top: 5px;
            span {
                color: #d2bc0d;
                cursor: pointer;
                font-weight: bold;
            }
        }
        .text2 {
            font-size: 1.5vh;
            line-height: 1.5;
            opacity: 0.7;
            color: #fff;
            box-sizing: border-box;
            max-width: 464px;
            overflow-wrap: break-word;
            margin: 0 auto;
        }
        .img18 {
            margin-top: 1vh;
            img {
                width: 60%;
            }
        }
        .link {
            max-width: 350px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            padding: 1vh 0;
            margin-top: 0;
            margin-right: auto;
            margin-left: auto;
            img {
                width: 18%;
                cursor: pointer;
            }
        }
        .datBox {
            width: 100%;
            margin: 0 auto;
            padding: 0 1vh;
            .dat {
                width: 100%;
            }
        }
        .zuo {
            display: block;
            width: 80%;
            margin: 0 auto;
            position: relative;
            img {
                width: 100%;
                margin-left: -15px;
                // cursor: pointer;
            }
            .name {
                position: absolute;
                top: 48%;
                left: 35%;
                font-style: italic;
                color: #e46d2b;
                font-weight: bold;
                font-size: 2vh;
                cursor: pointer;
            }
        }
        .you {
            display: block;
            max-width: 350px;
            margin: 0 auto;
            position: relative;
            img {
                width: 100%;
                margin-right: -15px;
                cursor: pointer;
            }
            .name {
                position: absolute;
                top: 48%;
                left: 20%;
                font-style: italic;
                color: #e46d2b;
                font-weight: bold;
                font-size: 1vh;
            }
        }
    }
}
</style>

<template>
    <div class="bg">
        <div class='index'>
            <div class="content" :class="isPC?'pcclass':'mobileclass'">
                <div class="title">
                    <img src="../assets/logo.png" alt="">
                </div>
                <div class="text">
                    <span>Grupo U1</span> é gerenciado pelo Centro de Registro de Jogos de Azar SIGAP
                    e é uma plataforma de jogos de azar legal e compativel. Todos os
                    bonus sao qarantidos.
                </div>
                <div class="text2">
                    Obrigado, queridos jogadores, pelo seu apoio !
                </div>
                <div class="img18">
                    <img src="../assets/18.png" alt="">
                </div>
                <div class="link">
                    <img src="../assets/wx.png" alt="">
                    <img src="../assets/fj.png" alt="" @click="openUrl('https://t.me/U1_Grupo')">
                    <img src="../assets/ins.png" alt="" @click="openUrl('https://www.instagram.com/u1grupo_oficial/?utm_source=ig_web_button_share_sheet')">
                </div>
                <div class="datBox">
                    <img src="../assets/dat.png" alt="" class="dat">
                </div>
                <div class="zuo">
                    <img src="../assets/zuo.png" alt="">
                    <div class="name" @click="openUrl('https://U1-Global.COM')">
                        U1-Global.COM
                    </div>
                </div>
                <!-- <div class="you" style="margin-top: -30px;">
                    <img src="../assets/you.png" alt="" @click="openUrl('https://www.instagram.com/u1grupo_oficial/?utm_source=ig_web_button_share_sheet')">
                    <div class="name">
                        U1-UPBET.COM.BR
                    </div>
                </div>
                <div class="zuo" style="margin-top: -30px;">
                    <img src="../assets/zuo.png" alt="" @click="openUrl('https://www.instagram.com/u1grupo_oficial/?utm_source=ig_web_button_share_sheet')">
                    <div class="name">
                        U1-UPBET.COM.BR
                    </div>
                </div>
                <div class="you" style="margin-top: -30px;">
                    <img src="../assets/you.png" alt="" @click="openUrl('https://www.instagram.com/u1grupo_oficial/?utm_source=ig_web_button_share_sheet')">
                    <div class="name">
                        U1-UPBET.COM.BR
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            innerWidth: window.innerWidth,
            isPC: true,
        }
    },
    created() {
        // 检查当前设备是否是pc
        const userAgent = navigator.userAgent;
        const isPC = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        this.isPC = isPC;
    },
    watch: {
    },
    methods: {
        openUrl(url) {
            window.open(url);
        }
    },
};
</script>
